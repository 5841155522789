import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Checkout from "../components/stripe-checkout"

class CheckoutPage extends React.Component
{
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const menu = data.site.siteMetadata.menuLinks
        const tagline = data.site.siteMetadata.tagline
    
        return (
          <Layout location={this.props.location} title={siteTitle} menuLinks={menu} tagline={tagline}>
            <SEO title="Teach, don't speak workshop - Checkout" />
            <h1>"Teach, don't speak" workshop</h1>
            <p class="pub-details">Workshops by Derek Featherstone</p>
            
            <p>You've read the details of the workshop, and you're ready to jump in! Excellent, and thank you! You still need to know details. I'll be honest, I wasn't really sure where to put pricing. On the previous page? On some other course listing page? I dunno. Honestly, I'm learning some of this stuff, and I'll get better. But this is the page where you actually sign up for the course and buy it.</p>

            <p>This is pre-launch pricing at $250 USD. It won't last for very long, as I'm considering it actually launched now. "Early bird" pricing will be $375 USD, and full price will be $500 USD. Right now, it's effectively half price. I'm capping registrations at 20 people. Some of you know me and I've even talked with some of you about this, so if you're ready to sign up, I'm ready for you...</p>


  
            <h2>Workshop contents</h2>

            <p>Here's a quick rundown of what you get when you sign up for the workshop:</p>

            <ul>
              <li><strong>6 hours of workshop, led by yours truly</strong>, divided into 4 hands-on sessions, conducted remotely via Google hangouts, zoom, or Bluejeans, 90 min each.</li>
              <li><strong>Recordings of all the sessions</strong> so you can go back to revisit afterwards, or catch up if you missed one.</li>
              <li><strong>Professionally prepared transcripts</strong> of the entire workshop so you won't miss a thing</li>
              <li><strong>Digital copies of all the worksheets</strong>, so you can use these templates over and over </li>
              <li><p><strong>Two 1:1 sessions with me</strong>, 30 minutes each. I'll send you an invitation to my personal slack so that we can connect there. Treat the sessions like a coaching session, or an AMA session, or a "let's make my next talk awesome" session. Maybe something like this:</p><p>The first one: use it to catch up on or ask questions about that session that you weren't able to attend live (Just sayin'. We both know it's going to happen, so why not plan for it, right?)</p><p>The second one: use it to review an upcoming presentation together or... well, whatever, I suppose!</p></li>
            </ul>

            <h2>The schedule</h2>

            <h3>Which dates?</h3>
            <p>The next course is: March 24, March 26th, March 31, April 2.</p>
            
            <h3>What time?</h3>
            <p><strong>Currently planning to hold the sessions sometime between 5pm - 8pm Ottawa time (Eastern time zone - GMT-5)</strong> which is 9pm - 12am midnight in the UK, 5am - 8am in Western Australia, and 2pm - 5pm on the west coast of North America. That feels like it just might work for most people that have shown interest so far. And we'll adjust the timing slightly so that each session might start at a slightly different time, so that it isn't SUPER inconvenient for people in the UK or Australia for EVERY session. We'll see how it feels and go from there.</p>

            <h2>The content</h2>

            <p>Here's a rundown of the 4 sessions (if you had signed up for the email notifications about this, you'll have seen this already, so consider it a refresher):</p>

            <ul>
            <li><strong>Session 1: Getting to know the Framework</strong><br />
              This is the core of my planning methods, and rather than hold back, I want to get into that right away. You'll see why almost immediately once we start.</li>

              <li><strong>Session 2: (Un)learning &amp; teaching</strong><br />
              There's a lot that we've learned about the way people teach... we've picked it up after years of being talked at. So let's unlearn some of that, shall we?</li>
              <li><strong>Session 3: Plan the learning</strong><br />
              We're going to take a topic area of your own choice, and we're going to build a plan around that. I'll guide you through, using the tools that I use every single time I take the stage.</li>
              <li><strong>Session 4: Tying it all together</strong><br />
              In this session, you'll actually present to the group (or each other, or just me - this will depend on how many people sign up!). You'll have about 4 minutes to demonstrate that you're using the framework (yes, that will be harder than you think!) To wrap up, you'll set some goals, we'll have a chance for some open Q&amp;A with me.</li>
            </ul>

            <h2>That's it!</h2>

            <p>I'm grateful that you're interested in this workshop. There's not too much more I can say. Oh, there is one more thing. I'm going to offer you a 100% money back guarantee. No questions asked. Well, one question. I'll ask why, and you can tell me, and I'll learn from that and get better.</p>


            <p>Not quite ready to buy yet? No problem. If you're not already on my email list to get notified, <a href="https://mailchi.mp/f133934f2b0d/feather">sign up to let me know you're interested</a>. I'll let you know more about the course, and let you know when the next dates are.</p>
          </Layout>
        )
      }

}

export default CheckoutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        tagline
        menuLinks {
          name
          link
        }
      }
    }
  }
`